import React from 'react'
import { Link, graphql } from 'gatsby'
import JobHeader from 'components/job-header'

import Layout from 'components/layout'

const JobPage = ({ data }) => (
  <Layout>
    <JobHeader 
        job={data.job} 
        sizes={data.headerImage.childImageSharp.fluid}
      />
    <div className="l-primary">
      <nav>
        <h3>Careers</h3>
        <ul>
          <li>
            <Link to={'/careers/open-positions'}>Open Positions <span className="label">{data.allJobs.totalCount}</span></Link>
            {data.allJobs.edges.length > 0 && (
              <ul>
                {data.allJobs.edges.map(job => (
                  <li><Link to={job.node.fields.slug}>{job.node.frontmatter.position}</Link></li>
                ))}
              </ul>
            )}
          </li>
          <li><Link to={'/careers/company-culture'}>Company Culture</Link></li>
          <li><Link to={'/careers/core-values'}>Core Values</Link></li>
        </ul> 
      </nav>
      <section>
        <dl>
          <dt>Status</dt>
          <dd>{data.job.frontmatter.status}</dd>
          <dt>Salary</dt>
          <dd>{data.job.frontmatter.salary}</dd>
          <dt>Location</dt>
          <dd>{data.job.frontmatter.location}</dd>
          <dt>Reports To</dt>
          <dd>{data.job.frontmatter.reports_to}</dd>
        </dl>
        <div 
          dangerouslySetInnerHTML={{ __html: data.job.html }} 
        />
      </section>
      <aside className="apply-now-sticky">
          <h3>Apply Now</h3>
          <p>Applicants should email a cover letter and resume to <a href={`mailto:hr@howellfarms.com?subject=Application: ${data.job.frontmatter.position}`}>hr@howellfarms.com</a>.</p>
          <a href={`mailto:hr@howellfarms.com?subject=Application: ${data.job.frontmatter.position}`} className="buttonLink--small">Apply Now</a>
        </aside>
    </div>
  </Layout>
)

export const query = graphql`
  query($slug: String!) {
    job: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        position
        status
        salary
        reports_to
        location
      }
    }
    allJobs: allMarkdownRemark(
      filter: { 
        fields: { 
          type: { eq: "jobs"}
        }
      }, 
      sort: {fields: [frontmatter___position], order: ASC}
    ) {
      totalCount
      edges {
        node {
          fields {
            type
            slug
          }
          html
          frontmatter {
            position
            status
            salary
            reports_to
            location
          }
        }
      }
    }
    headerImage: file(relativePath: { eq: "farm-team-header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default JobPage
