import React from 'react'
import Img from 'gatsby-image'
import PageTitle from 'components/page-title'

import './job-header.scss'

const JobHeader = ({ job, sizes }) => (
  <div className="job-header">
    <PageTitle title={job.frontmatter.position} />
    <Img sizes={sizes} />
    <div className="content">
      <h1>{job.frontmatter.position}</h1>
    </div>
  </div>
)

export default JobHeader
